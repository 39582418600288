import React from "react"
import Seo from "../components/seo"
import Footer from "../components/footer"
import "../css/style.css"
import Video from "../components/video"

const CotorreoPage = () => (
  <>
    <Seo title="Video" />
    <Video />
    <div className="footer-1">
      <Footer />
    </div>
  </>
)

export default CotorreoPage
